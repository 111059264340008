import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { debounce } from 'lodash';

import routesMap from '../../Routes';
import {
  registerCard, isCardTypeValid, isCardNumberValid, errorMessages, getBrowserInfo,
} from '../../services/mangopay';
import callApi from '../../services/api';
import { pushToLayer } from '../../services/googleTagManager';
import { getZone } from '../../core/services/zipcode';
import withUptradeContext from '../../withUptradeContext';
import withCustomerContext from '../../withCustomerContext';
import { Button as ButtonV3, StyledTitle2 as Title2 } from '../home/v3/styledComponents';
import CreditCard from '../order/CreditCard';
import CreditCardsSelector from '../order/CreditCardsSelector';
import PopUpWaitTime from '../order/PopUpWaitTime';
import Modal from '../home/v3/Modal';

import logoMangopay from '../../assets/Mangopay2.png';
import logoMMA from '../../assets/mma.png';

function trackOrder(order, isFirstOrder, promoCode) {
  const zone = getZone(order.fullAddress.zipcode);
  pushToLayer({
    event: 'Order Sent',
    funnel_type: order.funnelType,
    area: zone && zone.id,
    order_id: order._id,
    promo_code: promoCode ?? '',
    is_first_order: !!isFirstOrder,
    is_logged_in: true,
    order_amount: Math.round(order.totalPaid * 100),
    user_id: typeof order.customer === 'object'
      ? order.customer._id
      : order.customer,
  });
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-bottom: 20px;
  }
`;

const GuaranteesContainer = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 15px;
  align-items: baseline;
  white-space: pre-wrap;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-top: 0px;
    margin-bottom: 15px;
    align-items: flex-start;
  }
`;

const Logo = styled.img`
  height: 17px;
  width: auto;
  margin-right: ${(props) => props.marginRight}px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-right: ${(props) => props.marginRight - 6}px;
  }
`;

const Button = styled(ButtonV3)`
  width: 245px;
  padding: 3px 15px 0px;
  margin-top: 59px;
  font-size: 18px;
  ${(props) => props.marginRight && 'margin-right: 45px;'}
  ${(props) => !props.isAvailable && `
    background-color: ${({ theme }) => theme.colors.lightGrey3};
    color: ${({ theme }) => theme.colors.white};
    pointer-events: none;
    cursor: unset;
    border-color: ${({ theme }) => theme.colors.lightGrey3};`} 
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 13px;
    width: 187px;
    min-width: unset;
    margin: 25px auto 0px;
    ${(props) => props.mobileSmallMarginTop && 'margin-top: 10px;'}
  }
`;

const CreditCardInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const CreditCardForm = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ErrorText = styled.p`
  font-family: Roboto;
  line-height: 20px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.error};
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    line-height: 17px;
    font-size: 12px;
    text-align: center;
  }
`;

const ModalTitle = styled(Title2)`
  width: 400px;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 25px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 80%;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 10px;
  }
`;

const ModalP = styled.p`
  font-size: 18px;
  line-height: 28px;
  margin: 0px 0px 23px;
  color: ${({ theme }) => theme.colors.navy};
  font-family: Roboto;
  width: 425px;
  text-align: center;
  margin: 0px;
  white-space: pre-wrap;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 85%;
    font-size: 14px;
    line-height: 20px;
    margin: 0px 0px 10px;
  }
`;

class CreditCardInfos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardName: '',
      cardNumber: '',
      cvv: '',
      selectedCardIndex: 0,
      isAddNewCardSelected: false,
      showPopupWaitTime: false,
    };
    this.submitOrderAndCardDebounced = debounce((isCardNew) => this.submitOrderAndCard(isCardNew), 2000, { leading: true, trailing: false });
  }

  setCreditCardInputValue = (input, value) => {
    if (input === 'cardNumber') this.validateCardNumber(value);
    this.setState({ [input]: value, formError: false });
  }

  validateCardNumber = (cardNumber) => {
    const strippedCardNumber = cardNumber.replace(/ /g, '');
    if (!isCardTypeValid(strippedCardNumber)) {
      this.setState({
        cardError: 'Les cartes American Express (Amex) ne sont pas supportées.',
      });
    } else if (
      strippedCardNumber
      && strippedCardNumber.length > 15
      && !isCardNumberValid(strippedCardNumber)
    ) {
      this.setState({
        cardError: 'Oups, une erreur s’est glissée. Êtes-vous sûr du numéro ?',
      });
    } else {
      this.setState({ cardError: null });
    }
  }

  selectCard = (selectedCardIndex) => {
    this.setState({ selectedCardIndex });
  }

  addNewCard = () => {
    this.setState({ isAddNewCardSelected: true });
  }

  isValid = () => {
    const {
      expiryMonth, expiryYear, cvv, cardNumber,
    } = this.state;
    return (
      expiryMonth
      && expiryYear
      && cvv
      && cardNumber
    );
  }

  setShow3DSModal = (redirectUrl) => {
    const { show3DSModal, redirectUrlState } = this.state;
    this.setState({ show3DSModal: !show3DSModal });
    if (redirectUrl) {
      this.setState({ redirectUrlState: redirectUrl });
      setTimeout(() => {
        this.setState({ show3DSModal: false });
        window.location = redirectUrl;
      }, 5000);
    }
    if (redirectUrlState) {
      window.location = redirectUrlState;
    }
  }

  redirect3DS = (redirectUrl) => {
    if (redirectUrl) {
      this.setShow3DSModal(redirectUrl);
      window.onbeforeunload = null;
    } else {
      navigate(routesMap.PayInSuccessPage.url);
    }
  }

  submitCard = (isCardNew, customerId, orderId) => {
    const { customerContext: { customer } } = this.props;
    if (isCardNew) {
      const {
        expiryMonth, expiryYear, cvv, cardNumber, cardId,
      } = this.state;
      const expiryDate = `${expiryMonth}${expiryYear}`;
      const cardData = {
        expiryDate, cvv, cardNumber, cardId,
      };
      return registerCard(cardData, customerId)
        .then((card) => {
          const BrowserInfo = getBrowserInfo();
          return callApi('public/submitCard', 'post', {
            card, orderId, BrowserInfo, isPartnerProduct: true, isCardNew: true,
          });
        })
        .then((res) => { this.redirect3DS(res?.redirectUrl); })
        .catch((response) => {
          this.setState({ error: true, showPopupWaitTime: false, errorType: response.error });
          if (typeof Raven !== 'undefined') {
            Raven.captureException(JSON.stringify(response)); // eslint-disable-line
          } else {
            console.error(response);
          }
        });
    }
    const { selectedCardIndex } = this.state;
    const BrowserInfo = getBrowserInfo();
    return callApi('public/submitCard', 'post', {
      card: { CardId: customer.cards[selectedCardIndex].id }, orderId, BrowserInfo, isPartnerProduct: true, isCardNew: false,
    }).then((res) => this.redirect3DS(res?.redirectUrl)).catch((response) => {
      this.setState({ error: true, showPopupWaitTime: false, errorType: response.error });
      if (typeof Raven !== 'undefined') {
        Raven.captureException(JSON.stringify(response)); // eslint-disable-line
      } else {
        console.error(response);
      }
    });
  }

  submitOrderAndCard = (isCardNew) => {
    if (isCardNew && !this.isValid()) return this.setState({ formError: true });
    this.setState({ showPopupWaitTime: true });
    const {
      uptradeContext: {
        request, endpoint, promoCode, setCustomerAndOrderId, orderId: orderIdUptrade, customerId: customerIdUptrade,
      },
    } = this.props;
    if (customerIdUptrade && orderIdUptrade) return this.submitCard(isCardNew, customerIdUptrade, orderIdUptrade);
    return callApi(endpoint, 'post', request)
      .then(async (res) => {
        trackOrder(res.order, res.isFirstOrder, promoCode && promoCode.code);
        setCustomerAndOrderId(res.customer._id, res.order._id);
        return ({ customerId: res.customer._id, orderId: res.order.id });
      })
      .then(({ customerId, orderId }) => {
        this.submitCard(isCardNew, customerId, orderId);
      })
      .catch((response) => {
        this.setState({ showPopupWaitTime: false });
        if (typeof Raven !== 'undefined') {
          Raven.captureException(JSON.stringify(response)); // eslint-disable-line
        } else {
          console.error(response);
        }
      });
  }

  renderCreditCardForm = () => {
    const {
      cardName, cardNumber, cvv, cardError, showPopupWaitTime,
    } = this.state;
    return (
      <CreditCardForm>
        <CreditCard
          onChange={this.setCreditCardInputValue}
          cardName={cardName}
          cardNumber={cardNumber}
          cvv={cvv}
          error={cardError}
        />
        <GuaranteesContainer alignItems="center" row marginBottom="0">
          <Logo marginRight={12} src={logoMangopay} alt="Logo Mangopay" />
          Règlement 100% sécurisé via Mangopay
        </GuaranteesContainer>
        <GuaranteesContainer marginLeft justifyContent="left" row marginsMobile alignItems="center">
          <Logo marginRight={15} src={logoMMA} alt="Logo MMA" />
          Vos vêtements sont garantis par notre partenaire MMA
        </GuaranteesContainer>
        <Button isAvailable={!showPopupWaitTime} navy onClick={() => this.submitOrderAndCardDebounced(true)}>Payer ma commande</Button>
        {this.renderError()}
      </CreditCardForm>
    );
  }

  renderCreditCardFormContainer = () => (
    <CreditCardInfosContainer>
      {this.renderCreditCardForm()}
    </CreditCardInfosContainer>
  )

  renderError = () => {
    const { error, errorType, formError } = this.state;
    const message = (errorType && errorMessages[errorType]) || 'Une erreur s’est produite...';
    return (
      <>
        {error && <ErrorText>{message}</ErrorText>}
        {formError && (
          <ErrorText>Merci de remplir tous les champs</ErrorText>
        )}
      </>
    );
  }

  render() {
    const {
      selectedCardIndex, isAddNewCardSelected, showPopupWaitTime, show3DSModal,
    } = this.state;
    const { customerContext: { customer } } = this.props;
    return (
      <Container>
        <div>
          {showPopupWaitTime && !show3DSModal && (<PopUpWaitTime />)}
          {show3DSModal && (
            <Modal closeModal={this.setShow3DSModal} hasCross modalWidth={785}>
              <ModalTitle>Sécurisation des transactions en ligne</ModalTitle>
              <ModalP>Afin que vos transactions en ligne soient sécurisées, vous allez être redirigé vers votre banque.</ModalP>
            </Modal>
          )}
          {(!customer || !customer.cards || customer.cards.length === 0)
            && this.renderCreditCardFormContainer()}

          {(customer?.cards || [])?.length > 0 && (
            <CreditCardsSelector
              cards={customer.cards}
              selectedCardIndex={selectedCardIndex}
              isAddNewCardSelected={isAddNewCardSelected}
              selectCard={(index) => this.selectCard(index)}
            />
          )}

          {customer && customer.cards
            && customer.cards.length > 0 && !isAddNewCardSelected
            && (
              <ButtonsContainer>
                <Button isAvailable navy marginRight onClick={() => this.submitOrderAndCardDebounced(false)}>Choisir cette carte</Button>
                <Button isAvailable transparent mobileSmallMarginTop onClick={this.addNewCard}>Ajouter une carte</Button>
              </ButtonsContainer>
            )}
          {isAddNewCardSelected && this.renderCreditCardForm()}
        </div>
      </Container>
    );
  }
}

CreditCardInfos.propTypes = {
  uptradeContext: PropTypes.shape({
    partnerProducts: PropTypes.arrayOf(PropTypes.shape({})),
    promoCode: PropTypes.shape({
      code: PropTypes.string,
    }),
    discounts: PropTypes.arrayOf(PropTypes.shape({})),
    request: PropTypes.shape({}),
    endpoint: PropTypes.string,
    setCustomerAndOrderId: PropTypes.func,
    orderId: PropTypes.string,
    customerId: PropTypes.string,
  }).isRequired,
  customerContext: PropTypes.shape({
    customer: PropTypes.shape({
      _id: PropTypes.string,
      address: PropTypes.shape({}),
      cards: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
      })),
    }),
  }).isRequired,
};

export default withCustomerContext(withUptradeContext(CreditCardInfos));
