import React from 'react';
import styled from 'styled-components';

import Layout from '../../layouts/index';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import CreditCardInfos from '../../components/TilliUptrade/CreditCardInfos';
import ProgressBar from '../../components/TilliUptrade/ProgressBar';
import { Container, Content, StyledTitle2 as Title2 } from '../../components/home/v3/styledComponents';
import { StepAndButtonContainer, StepContainer } from '../../components/TilliUptrade/styledComponents';
import PartnersPager from '../../components/home/v3/PartnersPager';

import './style.css';

const CreditCardInfosContainer = styled.div`
  width: 100%;
  margin-bottom: 120px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 7px;
    width: auto;
  }
`;

const BorderedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.navy};
  font-family: Roboto;
  margin-top: 80px;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: solid 8px ${({ theme }) => theme.colors.blueGrey};
  box-shadow: 0px 1px 6px ${({ theme }) => theme.colors.blueGrey};
  padding: 52px 142px 61px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    padding: 25px 20px 30px;
    margin-top: 34px;
  }
`;

const CreditCardInfosPage = () => (
  <Layout routeSlug="CreditCardInfosPage">
    <Container>
      <MenuBar showGiftBanner={false} />
      <Content hasNoBanner>
        <StepAndButtonContainer>
          <StepContainer>
            <ProgressBar step={3} />
            <CreditCardInfosContainer>
              <BorderedContainer>
                <Title2>Renseignez votre carte bancaire</Title2>
                <CreditCardInfos />
              </BorderedContainer>
            </CreditCardInfosContainer>
          </StepContainer>
        </StepAndButtonContainer>
      </Content>
      <PartnersPager linen />
      <Footer />
    </Container>
  </Layout>
);

export default CreditCardInfosPage;
